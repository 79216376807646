import style from './audio-recorder.module.css'

import { useRollbar } from '@rollbar/react'
import { Toast } from 'antd-mobile'
import Microphone from 'assets/microphone.svg'
import Play from 'assets/play.svg'
import Stop from 'assets/stop.svg'
import Trash from 'assets/trash.svg'
import { CircleButton } from 'components/common/circle-button'
import { observer } from 'mobx-react-lite'
import { JSX, useCallback, useEffect, useRef, useState } from 'react'
import { audioStore } from 'store/audio.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { AudioProgress } from './audio-progress'
import { MicrophonePermissionPopup } from './microphone-popup'

const mimeType = 'audio/mp3'

export const AudioRecorder = observer(() => {
  const { recordedAudio, time } = audioStore
  const [recordingStatus, setRecordingStatus] = useState('inactive')
  const [audioChunks, setAudioChunks] = useState<Array<Blob>>([])
  const [isShowPermissionPopup, setIsShowPermissionPopup] = useState(false)
  //@ts-ignore
  const [isPermissionRequested, setIsPermissionRequested] = useState(false)
  const [isPermissionDenied, setIsPermissionDenied] = useState(false)
  const rollbar = useRollbar()

  const mediaRecorder = useRef<MediaRecorder>(null)
  const timerRef = useRef<number>(0)

  const stopRecording = useCallback(() => {
    setRecordingStatus('inactive')

    window.clearInterval(timerRef.current)

    if (!mediaRecorder.current) {
      rollbar.error('MediaRecorder не найден')
      return
    }

    //stops the recording instance
    mediaRecorder.current.stop()
    mediaRecorder.current.onstop = () => {
      //creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: mimeType })
      //creates a playable URL from the blob file.
      const audioUrl = URL.createObjectURL(audioBlob)
      audioStore.setRecordedAudio(new Audio(audioUrl))
      setAudioChunks([])

      const tracks = audioStore.stream?.getAudioTracks() || []
      tracks.forEach((track) => track.stop())

      // Очистка потока в хранилище
      audioStore.setStream(null)
    }
  }, [audioChunks])

  useEffect(() => {
    if (time === 50000) {
      stopRecording()
    }
  }, [time, stopRecording])

  const requestPermission = async () => {
    try {
      // Проверяем текущее состояние разрешения
      const permissionStatus = await navigator.permissions.query({
        //@ts-ignore
        name: 'microphone',
      })

      if (permissionStatus.state === 'granted') {
        // Если доступ разрешен, начинаем запись
        startRecording()
        setIsPermissionRequested(true) // Помечаем, что разрешение было запрошено
        setIsPermissionDenied(false) // Сбрасываем флаг отказа
      } else if (permissionStatus.state === 'denied' || isPermissionDenied) {
        // Если доступ был запрещен или ранее был отказ
        setIsShowPermissionPopup(true) // Открываем попап снова
        setIsPermissionDenied(true) // Устанавливаем флаг отказа
      } else {
        // Если пользователь еще не ответил
        setIsShowPermissionPopup(true)
      }
    } catch (error) {
      //@ts-ignore
      rollbar.error('Ошибка при проверке разрешений: ' + error.message)
      Toast.show('Ошибка при доступе к микрофону')
    }
  }

  const startRecording = async () => {
    try {
      // Проверка состояния разрешения
      const permissionStatus = await navigator.permissions.query({
        // @ts-ignore
        name: 'microphone',
      })

      if (
        permissionStatus.state === 'granted' ||
        permissionStatus.state === 'prompt'
      ) {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        })

        audioStore.setPermission(true)
        audioStore.setStream(streamData)

        if (!streamData || !mediaRecorder) {
          rollbar.error(
            `Отсутствуют: streamData: ${Boolean(streamData)}, mediaRecorder: ${Boolean(
              mediaRecorder
            )}`
          )
          return
        }

        setRecordingStatus('recording')

        // @ts-ignore
        mediaRecorder.current = new MediaRecorder(streamData, {
          // @ts-ignore
          type: mimeType,
        })

        mediaRecorder.current.start()

        const localAudioChunks: Array<Blob> = []
        mediaRecorder.current.ondataavailable = (event: BlobEvent) => {
          if (typeof event.data === 'undefined' || event.data.size === 0) {
            return
          }
          localAudioChunks.push(event.data)
        }

        setAudioChunks(localAudioChunks)
        timerRef.current = window.setInterval(() => {
          audioStore.setTime(audioStore.time + 1000)
        }, 100)
        audioStore.setTime(0)
      } else {
        Toast.show(
          'Доступ к микрофону заблокирован. Разрешите доступ в настройках.'
        )
      }
    } catch (error) {
      // @ts-ignore
      rollbar.error('Ошибка при запуске записи: ' + error.message)
      Toast.show('Ошибка при доступе к микрофону')
    }
  }

  const handlePlayAudio = (): void => {
    if (!recordedAudio) {
      rollbar.error('Аудиофайл не найден')
      return
    }

    void recordedAudio.play()
  }

  const handleDeleteAudio = (): void => {
    setRecordingStatus('inactive')
    audioStore.setTime(0)
    window.clearInterval(timerRef.current)

    setAudioChunks([])
    audioStore.setRecordedAudio(null)
  }

  const getMainButton = (): JSX.Element => {
    return recordingStatus === 'recording' ? (
      <CircleButton onClick={stopRecording} icon={Stop} />
    ) : (
      <CircleButton onClick={requestPermission} icon={Microphone} />
    )
  }

  return (
    <div className={style.recordingWrapper}>
      <div className={style.recordingMain}>
        {getMainButton()}
        <div className={style.recordingProgress}>
          <AudioProgress time={time} />
          <CellEmpty height="15" />

          <p className={style.recordingTip}>
            Например, «Привет, меня зовут Иван! Буду рад&nbsp;познакомиться»
          </p>
        </div>
      </div>
      {isShowPermissionPopup && (
        <MicrophonePermissionPopup
          title="Разрешить доступ к микрофону"
          description="Для записи аудио необходимо разрешить доступ к микрофону."
          onConfirm={async () => {
            setIsShowPermissionPopup(false)
            startRecording()
            setIsPermissionDenied(false)
          }}
          onCancel={() => {
            setIsShowPermissionPopup(false)
            setIsPermissionDenied(true)
          }}
        />
      )}
      <div className={style.recordActions}>
        <CircleButton icon={Play} onClick={handlePlayAudio} />
        <CircleButton
          icon={Trash}
          buttonStyle={{ backgroundColor: '#BDBDBD' }}
          onClick={handleDeleteAudio}
        />
      </div>
    </div>
  )
})
