import { css } from '@emotion/css'
import Border from 'assets/decorations/meeting-details/timeslot-border.png'
import { flexCenterContent } from 'styles/common.style.ts'

export const chooseTimeslotContainer = css`
  padding: 4px 22px 14px;
  border-width: 2px;
  border-style: solid;
  border-color: #9fcbdc;
  border-image-source: url(${Border});
  border-image-repeat: stretch;
  border-image-slice: 8;
`

export const chooseTimeslotButton = css`
  ${flexCenterContent};
  width: 100%;
  height: 25px;
  border-radius: 12px;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
  background: #f4f4f4;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`

export const disabledTimeslot = css`
  pointer-events: none;
  opacity: 0.5;
`
export const chooseTimeslotButtonActive = css`
  background: #ffde00;
`

export const acceptButton = css`
  position: relative;
  width: 45%;
`

export const secondaryButton = css`
  background-color: rgba(191, 191, 191, 1);
  border: 3px solid rgba(242, 242, 242, 1);
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
  border-radius: 24.5px;

  width: 100%;
  height: 41px;
  margin: 20px auto;

  font-family: 'Copperplate' sans-serif;
  font-size: 20px;
  color: white;
  /* text-align: center; */
  font-weight: 700;
  padding: 5px;
`
