import { userInvitationsTransport } from 'api/user-invitations/invitations.transport.ts'
import { makeAutoObservable } from 'mobx'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { TPickerTimeslot } from 'types/time-picker.types.ts'

class InvitationAcceptStore {
  selectedTimeslot: TPickerTimeslot | null = null
  isAccepting: boolean = false
  isDeclining: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setSelectedTimeslot = (timeslot: TPickerTimeslot): void => {
    this.selectedTimeslot = timeslot
  }

  setCleanTimeslot = (): void => {
    this.selectedTimeslot = null
  }

  setIsLoading = (isAccepted: boolean, isLoading: boolean): void => {
    if (isAccepted) {
      this.isAccepting = isLoading
    } else {
      this.isDeclining = isLoading
    }
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  setIsInvitationAccepted = (
    invitationId: number,
    isAccepted: boolean,
    timeslot: TPickerTimeslot | null
  ): void => {
    this.setIsLoading(isAccepted, true)
    this.setHasError(false)

    userInvitationsTransport
      .setIsInvitationAccepted(invitationId, isAccepted, timeslot)
      .then(() => {
        const route = isAccepted
          ? SanekRoute.ACCEPT_INVITATION_SUCCESS
          : SanekRoute.MAIN
        void router.navigate(route, { replace: true })
      })
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(isAccepted, false)
      })
  }

  reset = (): void => {
    this.selectedTimeslot = null
    this.isAccepting = false
    this.isDeclining = false
    this.hasError = false
  }
}

export const invitationAcceptStore = new InvitationAcceptStore()
