import { makeAutoObservable } from 'mobx'

import { notificationsTransport } from 'api/notifications/notifications.transport.ts'
import { NotificationModel } from '../api/notifications/model/notificationModel.ts'

class NotificationsStore {
  notifications: NotificationModel[] = []
  isLoading = false
  hasError = false

  constructor() {
    makeAutoObservable(this)
  }

  setHasError = (hasError: boolean) => {
    this.hasError = hasError
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setNotifications = (notifications: NotificationModel[]) => {
    this.notifications = notifications
  }

  getNotifications = (): void => {
    this.setIsLoading(true)
    this.setHasError(false)

    notificationsTransport
      .fetchNotifications()
      .then(this.setNotifications)
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  get unreadNotificationsCount(): number {
    return this.notifications.filter((notification) => !notification.seen)
      .length
  }

  get unreadNotifications(): NotificationModel[] {
    return this.notifications.filter((notification) => !notification.seen)
  }

  get oldNotifications(): NotificationModel[] {
    return this.notifications.filter((notification) => notification.seen)
  }
}

export const notificationsStore = new NotificationsStore()
