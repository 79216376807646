import { css } from '@emotion/css'

const baseText = css`
  font-family: 'Copperplate', sans-serif;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #000;
  line-height: 1;
`

const flexContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const boxStyle = css`
  border: 3px solid #e2e2e2;
  border-radius: 29px;
  box-shadow: 2px 3px 0 0 rgba(0, 0, 0, 0.25);
  background: #fafafa;
`

export const headerBlock = css`
  ${flexContainer};
  justify-content: space-between;
  margin: 0 40px;
`

export const subscriptionBtn = css`
  ${flexContainer}
  ${boxStyle}
  ${baseText}
  padding: 10px;
  gap: 15px;
  width: 60%;
  height: 59px;
`

export const locationBtn = css`
  ${flexContainer};
  ${baseText};
  ${boxStyle};
  width: 35%;
  height: 59px;
  font-size: 15px;
`

export const vortexContainer = css`
  ${flexContainer};
  margin: 10px auto;
`

export const vortex = css`
  max-width: 100%;
  height: auto;
`

export const avatarsBlock = css`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin: 0 20px;
  justify-content: center;
`

export const userAvatar = css`
  width: 192px;
  height: 222px;
  object-fit: cover;
  z-index: 1;
  background-color: rgba(245, 40, 145, 0);
`

export const reverse = css`
  transform: scaleX(-1);
`

export const avatarsVortexBlock = css`
  ${flexContainer};
  width: 100%;
  overflow: hidden;
`

export const userLevelBlock = css`
  ${flexContainer}
  ${baseText}
  margin: 20px auto;
  gap: 20px;
  font-family: 'Chalkboard SE', sans-serif;
  font-size: 26px;
  line-height: 85%;

  span {
    position: relative;
  }
`

export const stroke = css`
  position: absolute;
  left: -5px;
  bottom: -15px;
  z-index: 1;
`

export const userLevel = css`
  ${boxStyle}
  ${baseText}
  border: 3px solid #ffde00;
  width: 60%;
`

export const buttonsBlock = css`
  ${flexContainer};
  justify-content: space-around;
  padding: 0 60px;

  div {
    ${flexContainer};
    flex-direction: column;
    gap: 10px;
  }
`

export const buttonText = css`
  ${baseText};
  font-weight: 300;
  font-size: 13px;
`

export const topBtn = css`
  ${flexContainer};
  ${boxStyle};
  ${baseText};
  width: 115px;
  height: 42px;
  font-family: 'Bitter', sans-serif;
  font-size: 24px;
`

export const buttonsBottomBlock = css`
  ${flexContainer};
  justify-content: space-between;
  padding: 15px 50px;
  margin-top: 30px;
  position: relative;
  z-index: 1;
`

export const bottomBtn = css`
  ${flexContainer};
  ${baseText};
  flex-direction: column;
  border: 5px solid #f2f2f2;
  border-radius: 40px;
  width: 114px;
  height: 119px;
  box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.25);
  background: #9fcbdc;
  padding: 10px;
  font-size: 16px;
  line-height: 127%;
  letter-spacing: -0.03em;
`

export const group = css`
  position: absolute;
  top: 540px;
  width: 90%;
  left: 5%;
`

export const bottomBlock = css`
  ${flexContainer};
  flex-direction: column;
  gap: 15px;
  padding: 0 35px 50px;
`

export const sanekBtn = css`
  ${baseText};
  font-size: 16px;
  letter-spacing: -0.03em;
`

export const whiteText = css`
  ${baseText};
  font-size: 16px;
  line-height: 272%;
  letter-spacing: -0.03em;
  color: #fff;
`
export const separator = css`
  padding: 0 40px;
`

export const userInfo = css`
  ${baseText}
`
export const userName = css`
  font-size: 40px;
  line-height: 40px;
`

export const userPhone = css`
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
`
