import { metroStationsTransport } from 'api/metro-stations.transport.ts'
import { MetroStationModel } from 'api/models/metro-station.model.ts'
import { makeAutoObservable } from 'mobx'

class MetroStationsStore {
  metroStations: Array<MetroStationModel> = []
  isLoading: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setMetroStations = (metroStations: Array<MetroStationModel>): void => {
    this.metroStations = metroStations
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  getMetroStations = async (cityId?: number): Promise<void> => {
    this.setIsLoading(true)
    this.setHasError(false)

    try {
      const stations = await metroStationsTransport.getMetroStations(cityId)

      this.setMetroStations(stations)
    } catch (error) {
      this.setHasError(true)
    } finally {
      this.setIsLoading(false)
    }
  }
}

export const metroStationsStore = new MetroStationsStore()
