import { Toast } from 'antd-mobile'
import ButtonDecoration from 'assets/decorations/main/main-button-decoration.png'
import Frame from 'assets/Frame.svg'
import AfterSanek from 'assets/Group 59521.svg'
import Bulb from 'assets/invitation.svg'
import Message from 'assets/loudspeaker-2.svg'
import Vortex from 'assets/main-page-vortex-buttons.svg'
import ManTallMain from 'assets/man_tall_main.png'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { SVGImg } from 'ui/svg-img'
import { pluralize } from 'utils/pluralize.ts'
import * as styles from './meetup-buttons.styles'

type TMeetupButtonsProps = {
  meetingsCount: number
  invitationsCount: number
  hasSubscription: boolean
}

function MeetupButtons({
  meetingsCount,
  invitationsCount,
  hasSubscription,
}: TMeetupButtonsProps) {
  const handleNavigateToCreateMeeting = (): void => {
    if (!hasSubscription) {
      Toast.show({ content: <p>Продлите подписку</p> })
      return
    }

    void router.navigate(SanekRoute.CREATE_MEETING)
  }

  const handleNavigateToMeetingsList = (): void => {
    void router.navigate(SanekRoute.INVITATIONS_LIST)
  }

  return (
    <div className={styles.mainContainer}>
      <div
        className={styles.addMeetUpButton}
        onClick={handleNavigateToCreateMeeting}
      >
        <img
          src={ButtonDecoration}
          alt="подчеркивание"
          className={styles.leftPng}
        />
        <div className={styles.rightSide}>
          <SVGImg src={Message} />
          <div className={styles.btnText}>СОЗДАТЬ ВСТРЕЧУ</div>
        </div>
      </div>

      <div className={styles.meetingsCountContainer}>
        <span className={styles.meetingsCount}>{meetingsCount}</span>
        &nbsp;
        {pluralize(
          ['встреча', 'встречи', 'встреч'],
          meetingsCount
        ).toUpperCase()}{' '}
        {pluralize(
          ['создана', 'создано', 'создано'],
          meetingsCount
        ).toUpperCase()}
      </div>

      <div
        className={styles.showInvitesButton}
        onClick={handleNavigateToMeetingsList}
      >
        <img
          src={ButtonDecoration}
          alt="подчеркивание"
          className={styles.leftPng}
        />
        <div className={styles.rightSide}>
          <SVGImg src={Bulb} />
          <div className={styles.btnText}>ПОСМОТРЕТЬ ПРИГЛАШЕНИЯ</div>
        </div>
      </div>

      <div className={styles.invitationsCountContainer}>
        <span className={styles.invitationsCount}>{invitationsCount}</span>
        {pluralize(
          ['приглашение', 'приглашения', 'приглашений'],
          invitationsCount
        )}
      </div>

      <img src={Vortex} className={styles.buttonVortexImg} />
      {/* svg */}
      <SVGImg
        src={Frame}
        style={{ position: 'absolute', left: '110px', top: '30px' }}
      />
      <img
        src={ManTallMain}
        alt="Санёк"
        width="174px"
        height="271px"
        className={styles.sanekPng}
      />
      <img src={AfterSanek} className={styles.afterSanek} />
    </div>
  )
}

export default MeetupButtons
