import * as styles from './notifications.style.ts'

import { FC, useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import { navToMainPage } from 'utils/navigation'

import { LayoutPage } from 'components/layout-page'
import { NotificationCard } from 'components/notification-card'

import { useNavigate } from 'react-router-dom'
import { notificationsStore } from 'store/notifications.store.ts'
import { SanekRoute } from '../../router/router.enums.ts'

export const NotificationsPage: FC = observer(() => {
  const navigate = useNavigate()
  const { getNotifications, oldNotifications, unreadNotifications } =
    notificationsStore

  useEffect(() => {
    getNotifications()
  }, [])

  const goToNotificationPage = (notificationId: number) => {
    navigate(`${SanekRoute.NOTIFICATIONS}/${notificationId}`)
  }

  return (
    <LayoutPage backAction={navToMainPage} headerText="Уведомления">
      <div className={styles.pageContainer}>
        <div className={styles.titleNew}>Новые</div>
        {unreadNotifications.map((notification) => (
          <NotificationCard
            key={notification.id}
            notification={notification}
            onClick={goToNotificationPage}
          />
        ))}

        <br />

        <div className={styles.titleOld}>Старые</div>
        {oldNotifications.map((notification) => (
          <NotificationCard
            key={notification.id}
            notification={notification}
            onClick={goToNotificationPage}
          />
        ))}
      </div>
    </LayoutPage>
  )
})
