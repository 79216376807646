// import MicrophoneIcon from 'assets/microphone-icon.png'
import { FC } from 'react'
import * as styles from './microphone-permission-popup.style'

type MicrophonePermissionPopupProps = {
  title: string
  description: string
  onConfirm: () => void
  onCancel: () => void
}

export const MicrophonePermissionPopup: FC<MicrophonePermissionPopupProps> = ({
  title,
  description,
  onConfirm,
  onCancel,
}) => {
  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popup}>
        <div className={styles.image}></div>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
        <div className={styles.actions}>
          <button onClick={onCancel} className={styles.cancel}>
            Отказаться
          </button>
          <button onClick={onConfirm} className={styles.confirm}>
            Разрешить
          </button>
        </div>
      </div>
    </div>
  )
}
