import { SanekUser } from 'api/models/sanek-user.model.ts'
import { makeAutoObservable } from 'mobx'

import { usersTransport } from 'api/users.transport.ts'
import { smsTransport } from '../api/sms.transport.ts'
class UserStore {
  user: SanekUser | null = null
  isLoading: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setUser = (user: SanekUser): void => {
    this.user = user
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  logout = (): void => {
    this.user = null
    localStorage.removeItem('jwt')
  }

  smsCodeVerify = async ({
    code,
    uuid,
  }: {
    code: string
    uuid: string
  }): Promise<void> => {
    return smsTransport.verifyCode({ code, uuid }).then((data) => {
      if (!data) return
      this.setUser(SanekUser.fromServer(data.sanek_user))
    })
  }

  getMe = (): Promise<void> => {
    this.setIsLoading(true)
    this.setHasError(false)

    return usersTransport
      .getMe()
      .then((data: SanekUser) => {
        this.setUser(data)
      })
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }
}

export const userStore = new UserStore()
