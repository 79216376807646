import { css } from '@emotion/css'

export const avatarContainer = css`
  position: relative;
  width: 47%;
`

export const avatarImage = css`
  display: block;
  width: 100%;
`

export const avatarStroke = css`
  position: absolute;
  left: -30%;
  bottom: -11px;
  width: 263px;
`

export const buttonsContainer = css`
  width: 173px;
`

export const statusButton = css`
  width: 173px;
  height: 56px;
  border: 3px solid #e2e2e2;
  border-radius: 30px;
  box-shadow: 2px 3px 0 0 rgba(0, 0, 0, 0.25);
  background: #fafafa;
  margin-bottom: 16px;
  padding: 0.5em 1em;

  img {
    width: 40px;
    margin-right: 10px;
  }
`

export const activeButton = css`
  background: #ffde00;
`
