import { MetroStationModel } from 'api/models/metro-station.model.ts'
import Loupe from 'assets/loupe.svg'
import { CenteredLoader } from 'components/common/centered-loader'
import { observer } from 'mobx-react-lite'
import React, { JSX, useEffect, useState } from 'react'
import { metroStationsStore } from 'store/metro-stations.store.ts'
import { SVGImg } from 'ui/svg-img'
import { closeAllDialogs } from 'utils/dialog.ts'
import { LayoutPage } from '../layout-page'
import * as styles from './metro-station-dialog.style.ts'

type TMetroStationSelectProps = {
  cityId?: number
  onSelect: (metroStation: MetroStationModel) => void
}
export const MetroStationSelectDialog = observer(
  ({ cityId, onSelect }: TMetroStationSelectProps): JSX.Element => {
    const { metroStations, isLoading } = metroStationsStore
    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
      metroStationsStore.getMetroStations(cityId)
    }, [cityId])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value)
    }

    const filteredMetroStations = metroStations.filter((station) =>
      station.name.toLowerCase().includes(searchQuery.toLowerCase())
    )

    const handleSelect = (metroStation: MetroStationModel): void => {
      console.log(metroStation, 'handleSelect')

      onSelect(metroStation)
      closeAllDialogs()
    }

    if (isLoading) {
      return <CenteredLoader />
    }
    console.log(
      metroStations,
      filteredMetroStations,
      'metroStations&filteredMetroStations'
    )

    return (
      <LayoutPage headerText="Фильтры" backAction={closeAllDialogs}>
        <div className={styles.mainContainer}>
          <div className={styles.headerBlock}>
            <p className={styles.title}>Список станций метро</p>
          </div>

          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              autoComplete="off"
              name="searchField"
              onChange={handleInputChange}
              value={searchQuery}
            />
            <SVGImg src={Loupe} />
          </div>

          <div className={styles.stationsListContainer}>
            {filteredMetroStations &&
              filteredMetroStations.map((station: MetroStationModel) => (
                <div
                  key={station.id}
                  className={styles.stationContainer}
                  onClick={handleSelect.bind(null, station)}
                >
                  <label
                    className={styles.label}
                    htmlFor={`station-${station.id}`}
                  >
                    <span
                      className={styles.metroIcon}
                      style={{ color: station.color }}
                    >
                      М
                    </span>
                    {station.name}
                  </label>
                </div>
              ))}
          </div>
        </div>
      </LayoutPage>
    )
  }
)
