import styles from './main-page.module.css'

import { PullToRefresh } from 'antd-mobile'
import { observer } from 'mobx-react-lite'
import { JSX, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Vortex from 'assets/decorations/main/vortex-main.svg'
import { CenteredLoader } from 'components/common/centered-loader'
import { MeetingsBlock } from 'components/meetings-block'
import { MeetupButtons } from 'components/meetup-buttons'
import { Slider } from 'components/slider'
import { SubscriptionInfoBlock } from 'components/subscription-info-block'
import { userStore } from 'store/user.store.ts'
import { CellEmpty } from 'ui/cell-empty'
import { UserAvatar } from 'ui/user-avatar'

import { AppService } from 'api/services/app.service.ts'
import { SeparatorGray } from 'components/common/separator'
import { NotificationsButton } from 'components/notifications-button/notifications-button.tsx'
import { PushNotificationsPopup } from 'components/push-notifications-popup'
import { userInvitationsStore } from 'store/invitations.store'
import { userMeetingsStore } from 'store/user-meetings.store.ts'

export const MainPage = observer((): JSX.Element => {
  const navigate = useNavigate()
  const { user, isLoading } = userStore
  const { isLoading: isUserMeetingsLoading, meetings } = userMeetingsStore

  const { isLoading: isUserInvitationsLoading, total: invitationsTotal } =
    userInvitationsStore

  useEffect(() => {
    AppService.initUserData()
  }, [])

  const filtersCount = userMeetingsStore.activeFiltersCount

  if (isLoading || isUserMeetingsLoading || isUserInvitationsLoading || !user) {
    return <CenteredLoader />
  }

  const goToNotifications = () => {
    navigate('/notifications')
  }

  const handleRefresh = (): Promise<void> => {
    return new Promise(AppService.initUserData)
  }

  return (
    <PullToRefresh onRefresh={handleRefresh}>
      <PushNotificationsPopup
        title="Санёк приветствует!"
        description="Чтобы не пропустить приглашение на встречу от нового друга"
        content="Разреши Саньку прислать уведомление"
      />
      <div className={styles.mainContainer}>
        <CellEmpty height={28} />
        <h3 className={styles.title}>САНЁК ЗОВЁТ</h3>
        <CellEmpty height={26} />

        <div className={styles.notifications}>
          <NotificationsButton onClick={goToNotifications} />
        </div>
        <div className={styles.header}>
          <div className={styles.leftSide}>
            <img src={Vortex} className={styles.vortex} alt="Vortex" />
            <UserAvatar image={user.avatar.image} />
            <div className={styles.separator}></div>
            <SubscriptionInfoBlock />
          </div>
          <Slider />
        </div>

        <SeparatorGray />
        <MeetupButtons
          meetingsCount={user?.createdMeetingsCount || 0}
          invitationsCount={invitationsTotal}
          hasSubscription={Boolean(
            user?.subscription && user.subscription.remainingDays > 0
          )}
        />
        <MeetingsBlock filtersCount={filtersCount} meetings={meetings} />
      </div>
    </PullToRefresh>
  )
})
