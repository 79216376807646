import Play from 'assets/play.svg'
import { CircleButton } from 'components/common/circle-button'
import { JSX, useEffect, useState } from 'react'
import * as styles from './audio-play-button.style.ts'

type TSoundPlayButtonProps = {
  audioSrc: string
}

export function AudioPlayButton({
  audioSrc,
}: TSoundPlayButtonProps): JSX.Element {
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null)

  useEffect(() => {
    const audioElement = new Audio(audioSrc)
    setAudio(audioElement)

    // Удаляем аудио при размонтировании компонента
    return () => {
      audioElement.pause()
      audioElement.src = ''
    }
  }, [audioSrc])

  const audioPlay = () => {
    if (!audio || isPlaying) return

    setIsPlaying(true)
    audio.play().catch((error) => {
      console.error('Ошибка воспроизведения аудио:', error)
      setIsPlaying(false)
    })

    audio.onended = () => {
      setIsPlaying(false)
    }
  }

  return (
    <>
      <CircleButton
        icon={Play}
        isLoading={isPlaying}
        className={styles.playButton}
        onClick={audioPlay}
        // Добавляем для мобильных устройств
      />
    </>
  )
}
