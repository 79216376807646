import { SeparatorGrayThin } from 'components/common/separator/separator-gray-thin.tsx'
import { SanekFriendInfo } from 'components/sanek-friend-info'
import { MEETING_RATE_DATA } from 'const/meeting-rate.const.ts'
import { observer } from 'mobx-react-lite'
import * as styles from 'pages/meeting-details/content/found-content/found-content.style.ts'
import { JSX } from 'react'
import { meetingDetailsStore } from 'store/meeting-details.store.ts'
import { TMeetingRateStep } from 'types/meeting-rate.types.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { Typography } from 'ui/typography'

export const ArchiveContent = observer((): JSX.Element => {
  const { meeting } = meetingDetailsStore

  if (!meeting) {
    return <></>
  }

  const rate = MEETING_RATE_DATA.find((step: TMeetingRateStep) => {
    return step.value === meeting.rate
  })

  return (
    <>
      {rate && (
        <>
          <Typography family="Chalkboard" size="24" align="center">
            Оценка встречи
          </Typography>
          <CellEmpty height="5" />

          <FlexContainer direction="column" justify="center" align="center">
            <img src={rate.icon} alt="" />
            <CellEmpty height="8" />
            <Typography weight="300" align="center">
              {rate.text}
            </Typography>
          </FlexContainer>
          <CellEmpty height="10" />
        </>
      )}

      <SeparatorGrayThin className={styles.separatorImage} />

      {meeting.counterpart && (
        <>
          <Typography family="Chalkboard" size="26" weight="700">
            Друг Санька
          </Typography>
          <CellEmpty height="15" />

          <SanekFriendInfo friend={meeting.counterpart} />
          <CellEmpty height="20" />
        </>
      )}
    </>
  )
})
