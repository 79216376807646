import { DotLoading, Toast } from 'antd-mobile'
import { sanekUsersTransport } from 'api/sanek-users.transport.ts'
import Man from 'assets/man.png'
import { observer } from 'mobx-react-lite'
import { FC, ReactNode, useEffect, useState } from 'react'
import { userStore } from 'store/user.store.ts'
import { urlBase64ToUint8Array } from 'utils/urlB64ToUint8Array.ts'
import * as styles from './push-notifications-popup.style.ts'

type Props = {
  title: string
  description: string
  content: ReactNode
}

enum PermissionStatus {
  GRANTED = 'granted',
  DENIED = 'denied',
  DEFAULT = 'default',
}

export const PushNotificationsPopup: FC<Props> = observer(
  ({ title, description, content }) => {
    const { user, setUser, isLoading: isLoadingUser } = userStore
    const [isLoading, setIsLoading] = useState(false)
    const [isShowPopup, setIsShowPopup] = useState(false)

    const isAndroid = /android/i.test(navigator.userAgent)

    const closePopup = () => {
      setIsShowPopup(false)
    }

    const hasSameSubscription = (subscription?: PushSubscription | null) => {
      if (!user || !user.pushSubscription || !subscription) return false

      const currentSubscription = user.pushSubscription
      const preparedSubscription = subscription.toJSON()

      if (!preparedSubscription) return false

      return (
        preparedSubscription.keys?.auth === currentSubscription.auth &&
        preparedSubscription.endpoint === currentSubscription.endpoint &&
        preparedSubscription.keys?.p256dh === currentSubscription.p256dh
      )
    }

    useEffect(() => {
      const checkSubscription = async () => {
        if (!user) return

        if (
          !('Notification' in window) ||
          !('serviceWorker' in navigator) ||
          !('PushManager' in window)
        ) {
          Toast.show('Ваш браузер не поддерживает push-уведомления')
          return
        }

        const serviceWorkerRegistration =
          await navigator.serviceWorker.getRegistration()
        const subscription =
          await serviceWorkerRegistration?.pushManager.getSubscription()

        // Если подписка уже есть и совпадает, не запрашиваем разрешение
        if (subscription && hasSameSubscription(subscription)) {
          return
        }

        // Показать всплывающее окно только если подписка отсутствует или отличается
        setIsShowPopup(true)
      }

      checkSubscription()
    }, [user])

    const enableNotifications = async () => {
      if (!user) return

      try {
        const serviceWorkerRegistration =
          await navigator.serviceWorker.getRegistration()

        if (!serviceWorkerRegistration) {
          throw new Error('Service Worker registration not found')
        }

        // Проверка существующей подписки
        const existingSubscription =
          await serviceWorkerRegistration.pushManager.getSubscription()

        // Если подписка существует и совпадает с сохраненной, ничего не делаем
        if (existingSubscription && hasSameSubscription(existingSubscription)) {
          setIsShowPopup(false)
          return
        }

        // Запрашиваем разрешение только если подписка отсутствует или отличается
        const permission = await Notification.requestPermission()

        if (permission !== PermissionStatus.GRANTED) {
          setIsShowPopup(false)
          if (isAndroid) {
            Toast.show(
              'Проверьте разрешения для уведомлений в настройках вашего Android устройства'
            )
          } else {
            Toast.show('Включите уведомления в настройках браузера')
          }
          return
        }

        setIsLoading(true)

        // Подписываем пользователя
        const options = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            import.meta.env.VITE_WEB_PUSH_PUBLIC_KEY
          ),
        }

        const newSubscription =
          await serviceWorkerRegistration.pushManager.subscribe(options)

        if (!newSubscription) {
          throw new Error('Subscription failed')
        }

        const updatedUser = await sanekUsersTransport.savePushSubscription({
          sanekUserId: user.id,
          pushSubscription: newSubscription,
        })

        setUser(updatedUser)
      } catch (error) {
        console.error('Ошибка в процессе подписки на уведомления:', error)
        Toast.show('Ошибка подключения нотификаций, обратитесь в поддержку')
      } finally {
        setIsLoading(false)
        setIsShowPopup(false)
      }
    }

    if (!isShowPopup || isLoadingUser) return null

    return (
      <div className={styles.popupOverlay}>
        <div className={styles.popup}>
          <div className={styles.image}>
            <img src={Man} height="90px" width="90px" />
          </div>
          <div className={styles.title}>{title}</div>
          <div className={styles.divider} />
          <div className={styles.description}>{description}</div>
          <div className={styles.content}>{content}</div>

          <div className={styles.actions}>
            <button
              onClick={closePopup}
              className={styles.cancel}
              disabled={isLoading}
            >
              Отказаться
            </button>
            <button
              onClick={enableNotifications}
              className={styles.confirm}
              disabled={isLoading}
            >
              {isLoading ? <DotLoading /> : 'Разрешить'}
            </button>
          </div>
        </div>
      </div>
    )
  }
)
