import cn from 'classnames'
import * as styles from './notification-card.style.ts'

import { FC } from 'react'

import { NotificationModel } from 'api/notifications/model/notificationModel.ts'

import Divider from 'assets/decorations/notification/notificationDivider.svg'
import dayjs from 'dayjs'

type Props = {
  onClick: (id: number) => void
  notification: NotificationModel
}

export const NotificationCard: FC<Props> = ({ notification, onClick }) => {
  const { title, body, createdAt } = notification
  console.log(notification)

  const createdDate = new Date(createdAt)

  const formattedDate = dayjs(createdDate).format('HH:mm')

  const handleClick = () => {
    onClick?.(notification.id)
  }

  const renderButtonText = () => {
    if (notification.entity === 'meeting') {
      return 'Перейти к встрече'
    }

    if (notification.entity === 'invitation') {
      return 'Посмотреть приглашения'
    }

    return 'На главный'
  }

  return (
    <div className={styles.notificationCard} onClick={handleClick}>
      <div>
        <div className={styles.time}>{formattedDate}</div>
        <div
          className={cn(styles.content, {
            [styles.seen]: notification.seen,
          })}
        >
          <div className={styles.title}>{title}</div>
          <div className={styles.body}>{body}</div>
          <div className={styles.action}>{renderButtonText()}</div>
        </div>
        <div>
          <img src={Divider} alt="Разделитель" />
        </div>
      </div>
    </div>
  )
}
