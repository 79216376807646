import { UserMeetingModel } from 'api/user-meetings/models/user-meeting.model'
import LeftSvg from 'assets/meet-header-left.svg'
import SettingsSvg from 'assets/settings.svg'
import { MeetingCard } from 'components/meeting-card'
import { useNavigate } from 'react-router-dom'
import { SanekRoute } from 'router/router.enums.ts'
import { Empty } from './empty'
import styles from './meetings-block.module.css'

type TMeetupBlockProps = {
  filtersCount: number
  meetings: Array<UserMeetingModel>
}

export const MeetingsBlock = ({
  filtersCount,
  meetings,
}: TMeetupBlockProps) => {
  const navigate = useNavigate()

  console.log(meetings, 'meetings')

  const handleSettingsClick = () => {
    navigate(`${SanekRoute.MEETING_FILTERS}`)
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <img src={LeftSvg} className={styles.leftSvg} />
        <span className={styles.title}>МОИ ВСТРЕЧИ</span>
        <img
          src={SettingsSvg}
          className={styles.settingsSvg}
          onClick={handleSettingsClick}
        />
        {filtersCount > 0 && (
          <span className={styles.filtersCount}>{filtersCount}</span>
        )}
      </div>
      {meetings.length > 0 ? (
        <div className={styles.meetingsBlock}>
          {meetings.map((meeting: UserMeetingModel) => (
            <MeetingCard key={meeting.id} meeting={meeting} />
          ))}
        </div>
      ) : (
        <Empty />
      )}
    </div>
  )
}
